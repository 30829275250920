import React, { useEffect, useState } from 'react';
import { useFinsalesContext } from '../FinsalesContext';
import FinishModal from './FinishModal';
import t from '../assets/locales/pl.json';
import {useParams} from "react-router-dom";

const ThankYouPage = () => {
  const [openFinishModal, setOpenFinishModal] = useState<boolean>(false);
  const {
    baseUrl,
    fallbackUrl,
    intervalSeconds = 180,
    attempts = 1,
    originToken,
    dataUri
  } = useFinsalesContext();
  const { origin_token } = useParams();

  useEffect(() => {
    setOpenFinishModal(true);
    let availableAttempts = attempts;
    let interval= intervalSeconds * 1000
    const status_url = `${baseUrl}/check_lead_status/${origin_token}/`;
    const checkLoanGranted = async () => {
      try {
        const response = await fetch(status_url);
        const {next_request_status, redirect_url} = await response.json();
        availableAttempts--;
        if (next_request_status == 'stop' && redirect_url) {
          window.location.replace(redirect_url);
        } else if (availableAttempts === -1 && redirect_url) {
          window.location.replace(redirect_url);
          clearInterval(checkLoanInterval);
        } else if (availableAttempts === -1) {
          window.location.replace(fallbackUrl);
          clearInterval(checkLoanInterval);
        } else if (next_request_status == 'stop') {
          window.location.replace(fallbackUrl);
          clearInterval(checkLoanInterval);
        }
      } catch (error) {
        console.error(error);
        window.location.replace(fallbackUrl);
        clearInterval(checkLoanInterval);
      }
    };

    checkLoanGranted();
    const checkLoanInterval = setInterval(checkLoanGranted, interval);

    return () => {
      clearInterval(checkLoanInterval);
    };
  }, [baseUrl, fallbackUrl, intervalSeconds, attempts, origin_token]);

  return (
    <div>
      <FinishModal t={t} open={openFinishModal} />
    </div>
  );
};

export default ThankYouPage;
